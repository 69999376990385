// Generated by Framer (6b748fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, optimizeAppear, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const RichTextWithFX = withFX(RichText);

const cycleOrder = ["fCZgXry4D"];

const serializationHash = "framer-6QbNz"

const variantClassNames = {fCZgXry4D: "framer-v-x1hsil"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], type: "tween"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, transition: transition2, x: 0, y: 0}

const animation1 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tag, width, ...props}) => { return {...props, MqI9f44uD: tag ?? props.MqI9f44uD ?? "Tag"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tag?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MqI9f44uD, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "fCZgXry4D", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-x1hsil", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fCZgXry4D"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1c04hsy"} data-border layoutDependency={layoutDependency} layoutId={"QZ5gMQlR8"} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 42, borderBottomRightRadius: 42, borderTopLeftRadius: 42, borderTopRightRadius: 42}}><RichTextWithFX __fromCanvasComponent __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={optimizeAppear("animate", "1rea55x", animation, "x1hsil")} children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FzcGVrdGEgNDAw", "--framer-font-family": "\"Aspekta 400\", \"Aspekta 400 Placeholder\", sans-serif", "--framer-font-size": "19px", "--framer-line-height": "27px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38)))"}}>Tag</motion.p></React.Fragment>} className={"framer-1rea55x"} data-framer-appear-id={"1rea55x"} fonts={["CUSTOM;Aspekta 400"]} initial={optimizeAppear("initial", "1rea55x", animation1, "x1hsil")} layoutDependency={layoutDependency} layoutId={"jC0ZHwB3o"} style={{"--extracted-r6o4lv": "var(--token-a31d5043-947c-4b72-8ac5-3a2cd17fc6f0, rgb(38, 38, 38))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", transformPerspective: 1200}} text={MqI9f44uD} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6QbNz.framer-27hwu5, .framer-6QbNz .framer-27hwu5 { display: block; }", ".framer-6QbNz.framer-x1hsil { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-6QbNz .framer-1c04hsy { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: min-content; }", ".framer-6QbNz .framer-1rea55x { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6QbNz.framer-x1hsil, .framer-6QbNz .framer-1c04hsy { gap: 0px; } .framer-6QbNz.framer-x1hsil > *, .framer-6QbNz .framer-1c04hsy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6QbNz.framer-x1hsil > :first-child, .framer-6QbNz .framer-1c04hsy > :first-child { margin-left: 0px; } .framer-6QbNz.framer-x1hsil > :last-child, .framer-6QbNz .framer-1c04hsy > :last-child { margin-right: 0px; } }", ".framer-6QbNz[data-border=\"true\"]::after, .framer-6QbNz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 43
 * @framerIntrinsicWidth 57
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"MqI9f44uD":"tag"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercHNaIMpKB: React.ComponentType<Props> = withCSS(Component, css, "framer-6QbNz") as typeof Component;
export default FramercHNaIMpKB;

FramercHNaIMpKB.displayName = "Blogs / Blog tags Copy";

FramercHNaIMpKB.defaultProps = {height: 43, width: 57};

addPropertyControls(FramercHNaIMpKB, {MqI9f44uD: {defaultValue: "Tag", displayTextArea: false, title: "Tag", type: ControlType.String}} as any)

addFonts(FramercHNaIMpKB, [{explicitInter: true, fonts: [{family: "Aspekta 400", source: "custom", url: "https://framerusercontent.com/assets/AEfYlIhKbMvfYJjz64K4roBkv1k.woff2"}]}], {supportsExplicitInterCodegen: true})